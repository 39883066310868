import React, { useState, useEffect } from 'react'
import { Container, Text, VStack, Box } from '@chakra-ui/react'
import Map from '../Map'
import Form from '../Form'
import { fetchMapData, findShortestPath, saveRouteHistory } from '../api'
import PropTypes from 'prop-types'

let count = 0
let startCity = ''
let endCity = ''

function ShortestPathFinder ({ isLogin, token, userId, selectedMap }) {
  const [mapData, setMapData] = useState(null)
  const [shortestPath, setShortestPath] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    async function fetchData () {
      try {
        const data = await fetchMapData(selectedMap, token)
        setMapData(data)
        document.body.style.backgroundColor = 'darkslategray'
        setShortestPath([])
      } catch (error) {
        setError(error.message)
      }
    }
    if (isLogin) {
      fetchData()
    }
  }, [isLogin, token, selectedMap])

  const handleSubmit = async (startCity, endCity) => {
    try {
      const result = await findShortestPath(startCity, endCity, token, selectedMap)
      const pathNames = result.shortestPath.map(city => city.name)
      setShortestPath(pathNames)
      setError(null)
      await saveRouteHistory(userId, startCity, endCity, pathNames, token, selectedMap)
    } catch (error) {
      setError('Failed to find the shortest path')
      setShortestPath([])
    }
  }

  const handleCityClick = (cityName) => {
    if (count % 2 === 0) {
      startCity = cityName
      count++
    } else {
      endCity = cityName
      handleSubmit(startCity, endCity)
      count++
    }
  }

  return (
    <Container maxW="container.lg" mt={4}>
    <VStack spacing={4} align="stretch">
      {error && <Text color="red.500">{error}</Text>}
      {mapData && <Form onSubmit={handleSubmit} cities={mapData.cities} shortestPath={shortestPath} />}
      {mapData && <Box mt={0} ><Map mapData={mapData} shortestPath={shortestPath} onCityClick={handleCityClick} selectedMap={selectedMap}/></Box>}
    </VStack>
  </Container>
  )
}

ShortestPathFinder.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  selectedMap: PropTypes.string.isRequired
}

export default ShortestPathFinder
