import axios from 'axios'

export async function fetchMapData (mapName, token) {
  try {
    const response = await axios.get(`https://api.group4.proxy.devops-pse.users.h-da.cloud/api/mapdata/${mapName}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch map data')
  }
}

export async function findShortestPath (startCity, endCity, token, selectedMap) {
  try {
    const response = await axios.get('https://api.group4.proxy.devops-pse.users.h-da.cloud/api/shortestpath-specific', {
      params: {
        startCityName: startCity,
        endCityName: endCity,
        mapName: selectedMap
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to find the shortest path')
  }
}

export async function fetchAllMaps (token) {
  try {
    console.log('Fetching all maps with token:', token)
    const response = await axios.get('https://api.group4.proxy.devops-pse.users.h-da.cloud/api/maps', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    console.log('response: ')
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch mapss')
  }
}

export const saveRouteHistory = async (userId, startCity, endCity, shortestPath, token, mapName) => {
  try {
    const response = await axios.post('https://api.group4.proxy.devops-pse.users.h-da.cloud/api/routehistory', {
      userId,
      startCity,
      endCity,
      shortestPath,
      mapName
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error(error.response?.data?.error || 'Failed to save route history')
  }
}

export async function fetchRouteHistory (userId, token, selectedMap) {
  try {
    const response = await axios.get(`https://api.group4.proxy.devops-pse.users.h-da.cloud/api/routehistory/${userId}/${selectedMap}?`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch route history')
  }
}

export async function fetchAllRouteHistory (userId, token) {
  try {
    const response = await axios.get(`https://api.group4.proxy.devops-pse.users.h-da.cloud/api/routehistory/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch route history')
  }
}

export async function deleteHistoryById (historyId, token) {
  try {
    const response = await axios.delete(`https://api.group4.proxy.devops-pse.users.h-da.cloud/api/history/${historyId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete history record')
  }
}

export async function deleteAllHistoryByUser (userId, token) {
  try {
    const response = await axios.delete(`https://api.group4.proxy.devops-pse.users.h-da.cloud/api/history/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete all history records')
  }
}
