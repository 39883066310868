import React, { useState, useEffect } from 'react'
import { Box, Button, FormControl, FormLabel, VStack, Text, Flex, useBreakpointValue } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import VirtualizedSelect from './VirtualizedSelect'

function Form ({ onSubmit, cities, shortestPath, selectedMap }) {
  const [startCity, setStartCity] = useState('')
  const [endCity, setEndCity] = useState('')

  useEffect(() => {
    if (cities.length > 0) {
      setStartCity(cities[0].name)
      setEndCity(cities[0].name)
    }
  }, [cities, selectedMap])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(startCity, endCity)
  }

  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
  const boxWidth = useBreakpointValue({ base: '100%', md: '20%' })
  const formPosition = useBreakpointValue({ base: 'relative', md: 'fixed' })
  const navHeight = useBreakpointValue({ base: '60px', md: '80px' })

  return (
    <Flex width="100%" direction={flexDirection} mt={navHeight}>
      <Box
        width={boxWidth}
        padding="20px"
        border="1px solid black"
        position={formPosition}
        left={{ md: '0' }}
        top={{ md: navHeight }}
        bottom={{ md: '0' }}
        backgroundColor="white"
        zIndex="1"
      >
        <form onSubmit={handleSubmit} id="pathForm">
          <VStack spacing={4} align="stretch">
            <FormControl id="startCity" isRequired>
              <FormLabel>Start City</FormLabel>
              <VirtualizedSelect
                options={cities}
                value={startCity}
                onChange={(e) => setStartCity(e.target.value)}
                placeholder="Select start city"
              />
            </FormControl>
            <FormControl id="endCity" isRequired>
              <FormLabel>End City</FormLabel>
              <VirtualizedSelect
                options={cities}
                value={endCity}
                onChange={(e) => setEndCity(e.target.value)}
                placeholder="Select end city"
              />
            </FormControl>
            <Button type="submit" colorScheme="teal">Find Shortest Path</Button>
            {shortestPath.length > 0 && (
              <Text mt={4}>Shortest Path: {shortestPath.join(' -> ')}</Text>
            )}
          </VStack>
        </form>
      </Box>
      <Box flex="1" padding="10px" marginLeft={{ md: '20%' }}>
        {/* Map or additional content can go here */}
      </Box>
    </Flex>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })).isRequired,
  shortestPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedMap: PropTypes.string.isRequired
}

export default Form
