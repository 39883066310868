import React, { useState } from 'react'
import { Box, Input } from '@chakra-ui/react'
import { FixedSizeList as WindowList } from 'react-window'
import PropTypes from 'prop-types'

const VirtualizedSelect = ({ options, value, onChange, placeholder, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (selectedValue) => {
    onChange({ target: { value: selectedValue } })
    setIsOpen(false)
  }

  const renderRow = ({ index, style }) => (
    <Box
      key={options[index].name}
      style={style}
      onClick={() => handleSelect(options[index].name)}
      cursor="pointer"
      _hover={{ bg: 'gray.100' }}
      p={2}
    >
      {options[index].name}
    </Box>
  )

  return (
    <Box {...props} position="relative">
      <Input
        value={value}
        placeholder={placeholder}
        onClick={() => setIsOpen(!isOpen)}
        readOnly
        cursor="pointer"
      />
      {isOpen && (
        <Box position="absolute" zIndex="1" width="100%" bg="white" boxShadow="md">
          <WindowList
            height={200} // Adjust the height as needed
            itemCount={options.length}
            itemSize={35} // Adjust the item size as needed
            width="100%"
          >
            {renderRow}
          </WindowList>
        </Box>
      )}
    </Box>
  )
}

VirtualizedSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default VirtualizedSelect
