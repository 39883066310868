import React from 'react'
import { Box, Button, Flex, Heading, Spacer, Select } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { Link, useNavigate, useLocation } from 'react-router-dom'

function Navbar ({ logout, selectedMap, setSelectedMap, maps }) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleMapChange = (e) => {
    const newMap = e.target.value
    setSelectedMap(newMap)
    if (location.pathname.includes('/history')) {
      navigate('/history')
    }
  }

  return (
    <Flex as="nav" p={4} bg="teal.500" color="white" align="center" position="fixed" top="0" width="100%" zIndex="1000" height={{ base: '70px', md: '80px' }}>
      <Box>
        <Heading as="h1" size="lg">Route Finder</Heading>
      </Box>
      <Spacer />
      <Flex align="center" mr={2}>
        <Box as="label" htmlFor="mapSelect" mr={2}>Select Map:</Box>
        <Select
          id="mapSelect"
          value={selectedMap}
          onChange={handleMapChange}
          colorScheme="teal"
          color="black"
        >
          {maps.map((map) => (
            <option key={map} value={map}>
              {map}
            </option>
          ))}
        </Select>
      </Flex>
      <Box>
        <Button as={Link} to="/" colorScheme="teal" variant="ghost" mr={2}>Home</Button>
        <Button as={Link} to="/history" colorScheme="teal" variant="ghost" mr={2}>History</Button>
        <Button as={Link} to="/allhistory" colorScheme="teal" variant="ghost" mr={2}>AllHistory</Button>
        <Button onClick={logout} colorScheme="teal" variant="ghost">Logout</Button>
      </Box>
    </Flex>
  )
}

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  selectedMap: PropTypes.string.isRequired,
  setSelectedMap: PropTypes.func.isRequired,
  maps: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default Navbar
