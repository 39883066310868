import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'

let zoomFix = 0

function Map ({ mapData, shortestPath, onCityClick, selectedMap }) {
  const mapRef = useRef(null)
  const [zoom, setZoom] = useState(1)

  const handleCityClick = (cityName) => {
    onCityClick(cityName)
  }

  const handleWheel = (event) => {
    event.preventDefault()
    setZoom((prevZoom) => {
      const newZoom = prevZoom - event.deltaY * 0.001
      console.log(`Wheel Zoom - prevZoom: ${prevZoom}, newZoom: ${newZoom}`) // Debugging line
      return Math.min(Math.max(newZoom, 0.5), zoomFix) // Limit zoom level between 0.5 and initialZoom
    })
  }

  const drawMap = (mapData, shortestPath) => {
    const mapElement = mapRef.current
    const containerWidth = mapElement.clientWidth
    const containerHeight = mapElement.clientHeight
    const scale = Math.min(containerWidth / mapData.mapsizeX, containerHeight / mapData.mapsizeY) * zoom

    if (selectedMap !== '50000' && selectedMap !== '10000' && selectedMap !== '1000') {
      mapElement.innerHTML = ''

      mapData.cities.forEach((city) => {
        const cityElement = document.createElement('div')
        cityElement.className = 'city'
        cityElement.style.left = `${(city.positionX * scale) + (containerWidth - mapData.mapsizeX * scale) / 2}px`
        cityElement.style.top = `${(city.positionY * scale) + (containerHeight - mapData.mapsizeY * scale) / 2}px`
        const cityTextElement = document.createElement('div')
        cityTextElement.className = 'city-text'
        cityTextElement.textContent = city.name
        cityElement.appendChild(cityTextElement)
        cityElement.style.position = 'absolute'
        cityElement.style.transform = 'translate(-50%, -50%)'
        cityElement.style.color = 'white'
        checkCity(selectedMap, cityElement, scale)
        cityElement.style.fontWeight = 'bold'
        cityElement.style.whiteSpace = 'nowrap'
        cityElement.style.padding = '2px 8px'
        cityElement.style.borderRadius = '3px'
        cityElement.style.pointerEvents = 'auto'
        cityElement.style.cursor = 'pointer'
        cityElement.addEventListener('click', () => {
          handleCityClick(city.name)
          cityElement.style.backgroundColor = 'green'
          cityTextElement.style.backgroundColor = 'green'
        })
        mapElement.appendChild(cityElement)
      })

      if (mapData.connections) {
        mapData.connections.forEach((connection) => {
          const startCity = mapData.cities.find((city) => city.name === connection.parent)
          const endCity = mapData.cities.find((city) => city.name === connection.child)
          if (!startCity || !endCity) return

          const startX = (startCity.positionX * scale) + (containerWidth - mapData.mapsizeX * scale) / 2
          const startY = (startCity.positionY * scale) + (containerHeight - mapData.mapsizeY * scale) / 2
          const endX = (endCity.positionX * scale) + (containerWidth - mapData.mapsizeX * scale) / 2
          const endY = (endCity.positionY * scale) + (containerHeight - mapData.mapsizeY * scale) / 2

          const connectionElement = document.createElement('div')
          connectionElement.className = 'connection'
          const distance = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2))
          connectionElement.style.width = `${distance}px`
          connectionElement.style.transform = `rotate(${Math.atan2(endY - startY, endX - startX)}rad)`
          connectionElement.style.position = 'absolute'
          connectionElement.style.left = `${startX}px`
          connectionElement.style.top = `${startY}px`
          connectionElement.style.borderBottom = '1px solid rgba(0, 0, 0, 0.2)'
          mapElement.appendChild(connectionElement)
        })
      }

      shortestPath.forEach((cityName, index) => {
        if (index < shortestPath.length - 1) {
          const startCity = mapData.cities.find((city) => city.name === cityName)
          const endCity = mapData.cities.find((city) => city.name === shortestPath[index + 1])
          if (startCity && endCity) {
            const startX = (startCity.positionX * scale) + (containerWidth - mapData.mapsizeX * scale) / 2
            const startY = (startCity.positionY * scale) + (containerHeight - mapData.mapsizeY * scale) / 2
            const endX = (endCity.positionX * scale) + (containerWidth - mapData.mapsizeX * scale) / 2
            const endY = (endCity.positionY * scale) + (containerHeight - mapData.mapsizeY * scale) / 2
            const connectionElement = document.createElement('div')
            connectionElement.className = 'connection highlighted'
            connectionElement.style.width = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2) + 'px'
            connectionElement.style.transform = `rotate(${Math.atan2(endY - startY, endX - startX)}rad)`
            connectionElement.style.position = 'absolute'
            connectionElement.style.left = startX + 'px'
            connectionElement.style.top = startY + 'px'
            connectionElement.style.borderBottom = '3px solid red'
            mapElement.appendChild(connectionElement)
          }
        }
      })
    } else {
      mapElement.innerHTML = ''
      const Element = document.createElement('h1')
      Element.textContent = 'The map is too large to draw'
      Element.style.color = 'white'
      Element.style.fontWeight = 'bold'
      Element.style.fontSize = '40px'
      mapElement.appendChild(Element)
    }
  }

  useEffect(() => {
    let initialZoomLevel = 1 // Default zoom level if the map is not one of the specified maps

    switch (selectedMap) {
      case 'skyrim':
        initialZoomLevel = 1.5
        break
      case '10':
        initialZoomLevel = 1.05
        break
      case '100':
        initialZoomLevel = 0.92
        break
      case '20':
      case '25':
        initialZoomLevel = 0.97
        break
      case '5':
        initialZoomLevel = 1.1
        break
      case '50':
        initialZoomLevel = 0.93
        break
      case 'germany':
        initialZoomLevel = 1.3
        break
      default:
        initialZoomLevel = 1 // Default value
    }

    setZoom(initialZoomLevel)
    zoomFix = initialZoomLevel
  }, [mapData])

  useEffect(() => {
    if (mapData) {
      drawMap(mapData, shortestPath)
      const handleResize = () => drawMap(mapData, shortestPath)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [mapData, shortestPath, zoom])

  useEffect(() => {
    const mapElement = mapRef.current
    mapElement.addEventListener('wheel', handleWheel)
    return () => mapElement.removeEventListener('wheel', handleWheel)
  }, [])

  return <Box ref={mapRef} id="map" data-testid="map" w="100%" h="100%" position="relative"></Box>
}

Map.propTypes = {
  selectedMap: PropTypes.string.isRequired,
  mapData: PropTypes.shape({
    mapsizeX: PropTypes.number,
    mapsizeY: PropTypes.number,
    cities: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      positionX: PropTypes.number,
      positionY: PropTypes.number
    })),
    connections: PropTypes.arrayOf(PropTypes.shape({
      parent: PropTypes.string,
      child: PropTypes.string
    }))
  }).isRequired,
  shortestPath: PropTypes.arrayOf(PropTypes.string),
  onCityClick: PropTypes.func
}

function checkCity (selectedMap, cityElement, scale) {
  if (typeof cityElement === 'undefined' || typeof scale === 'undefined') {
    console.error('cityElement or scale is not defined')
    return
  }

  let fontSize
  switch (selectedMap) {
    case '10':
      fontSize = 400 * scale
      break
    case '100':
      fontSize = 2500 * scale
      break
    case 'germany':
      fontSize = 3 * scale
      break
    case '20':
      fontSize = 500 * scale
      break
    case '25':
      fontSize = 600 * scale
      break
    case '5':
      fontSize = 350 * scale
      break
    case '50':
      fontSize = 1500 * scale
      break
    case 'skyrim':
    default:
      fontSize = 65 * scale
      break
  }

  cityElement.style.fontSize = `${fontSize}px`
}

export default Map
