import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import ShortestPathFinder from './components/ShortestPathFinder'
import History from './components/History'
import Navbar from './components/Navbar'
import './App.css'
import useAuth from './hooks/useAuth'
import { fetchAllMaps } from './api'
import AllHistory from './components/AllHistory'

function App () {
  const [isLogin, token, userId, logout] = useAuth()
  const [selectedMap, setSelectedMap] = useState('skyrim')
  const [maps, setMaps] = useState([])

  useEffect(() => {
    if (token) {
      fetchAllMaps(token).then(data => {
        setMaps(data)
      }).catch(error => {
        console.error('Failed to fetch maps:', error)
      })
    }
  }, [token])

  return (
    <Router>
      <Box>
        <Navbar logout={logout} selectedMap={selectedMap} setSelectedMap={setSelectedMap} maps={maps} />
        <Routes>
          <Route path="/history" element={<History isLogin={isLogin} token={token} userId={userId} selectedMap={selectedMap} />} />
          <Route path="/allhistory" element={<AllHistory isLogin={isLogin} token={token} userId={userId} logout={logout}/>} />
          <Route path="/" element={<ShortestPathFinder isLogin={isLogin} token={token} userId={userId} logout={logout} selectedMap={selectedMap} />} />
        </Routes>
      </Box>
    </Router>
  )
}

export default App
